<script>
import feather from "feather-icons";
import ProjectSingle from "./ProjectSingle.vue";
import categories from "../../data/categories";

export default {
  components: { ProjectSingle },
  data: () => {
    return {
      categories,
      projectsHeading: "Porfolio Projects",
      selectedCategory: "",
      searchProject: "",
      isLoading: true,
    };
  },
  computed: {
    // Get the filtered projects
    filteredProjects() {
      if (this.selectedCategory) {
        return this.filterProjectsByCategory();
      } else if (this.searchProject) {
        return this.filterProjectsBySearch();
      }
      return this.categories;
    },
  },
  methods: {
    // Filter projects by category
    filterProjectsByCategory() {
      return this.categories.filter((item) => {
        let category =
          item.category.charAt(0).toUpperCase() + item.category.slice(1);
        console.log(category);
        return category.includes(this.selectedCategory);
      });
    },
    // Filter projects by title search
    filterProjectsBySearch() {
      let project = new RegExp(this.searchProject, "i");
      return this.categories.filter((el) => el.title.match(project));
    },
    async preloadImages() {
      const imagePromises = this.filteredProjects.map((project) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = resolve;
          img.src = project.img;
        });
      });

      await Promise.all(imagePromises);
      this.isLoading = false;
    },
  },
  async mounted() {
    feather.replace();
    await this.preloadImages();
  },
  watch: {
    filteredProjects: {
      async handler() {
        this.isLoading = true;
        await this.preloadImages();
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <section class="pt-10 sm:pt-14">
    <div
      v-show="!isLoading"
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10 animate-fade-in"
    >
      <ProjectSingle
        v-for="project in filteredProjects"
        :key="project.id"
        :project="project"
      />
    </div>
  </section>
</template>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}
</style>
